import React, { useState } from 'react'
import { createContainer } from '../common/unstated-next'
import { AdminUserDataFragment } from './Layout.generated'

interface AppConfig {
  darkMode: boolean
}

const useLayoutProps = (props?: { adminUser?: AdminUserDataFragment }) => {
  const [toolbar, setToolbar] = useState<React.ReactNode | null>()
  const [title, setTitle] = useState<React.ReactNode | null>(null)
  const [config, setConfig] = useState<AppConfig>({ darkMode: false })
  return {
    title,
    setTitle,
    toolbar,
    setToolbar,
    config,
    setConfig,
    adminUser: props?.adminUser,
  }
}
export const LayoutContext = createContainer(useLayoutProps)
