import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Container,
  LinearProgress,
  Menu,
  MenuItem,
  Popper,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { AppSidebar, DRAWER_WIDTH, useToolbarStyles } from './AppSidebar'
import Refresh from '@material-ui/icons/Refresh'
import Brightness4 from '@material-ui/icons/Brightness4'
import { useApolloClient, useQuery } from '@apollo/client/react/hooks'
import createPersistedState from 'use-persisted-state'
import { useAppContext } from './AppContext'
import { routes } from '../../routes'
import Link from 'next/link'
import { PageTitle } from '../common/PageTitle'
import { Router, useRouter } from 'next/router'
import { useApolloNetworkStatus } from '../../apollo/network_status'
import { isSsrMode } from '../../consts'
import { useContainer } from '../common/unstated-next'
import { RefreshVersionAlert } from './RefreshVersionAlert'
import { LayoutContext } from './LayoutContext'
import { AuthenticationError, ForbiddenError } from 'apollo-server-errors'
import useCustomSnackbar from '../../lib/useSnackbar'
import { CurrentAdminUserDocument } from './Layout.generated'
import { useLogoutAdmin } from '../../data/useLogoutAdmin'
import { bindMenu, bindPopper, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { MaybeRtlBox } from '../common/MaybeRtlBox'
import { useCurrentConference, useCurrentConferenceName } from '../common/useCurrent'
import { configureScope, setUser } from '@sentry/nextjs'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import Image from 'next/image'
import TargetLogo from '../../../images/target-logo.png'
import { getUrl } from '../../lib/addLinkDomain'
import { useDebugListener } from './UseDebugListener'
import { LayoutProps } from './LayoutProps'
import { LicenseManager } from 'ag-grid-enterprise'
import Search from '@material-ui/icons/Search'
import { MoveSelectedProductButtonDocument } from '../registrations/MoveSelectedProductButton.generated'
import { Autocomplete } from '@material-ui/lab'

LicenseManager.setLicenseKey(
  'CompanyName=Dan Shternberg,LicensedApplication=Target Conferences,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-027083,ExpiryDate=6_April_2023_[v2]_MTY4MDczNTYwMDAwMA==53edfa1f47147e65bea94529585c9405'
)

export const useLayoutComponentStyle = makeStyles<Theme, { runningEnv?: 'dev' | 'staging' }>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: ({ runningEnv }) => {
        switch (runningEnv) {
          case 'dev':
            return 'hotpink'
          case 'staging':
            return 'cadetblue'
          default:
            return undefined
        }
      },
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    rtl: {
      direction: 'rtl',
    },
    search: {
      background: 'white',
    },
  })
)

const ToolbarItemsSink = () => {
  const { toolbar } = useContainer(LayoutContext)
  return <>{toolbar}</>
}

export const ToolbarTitle = () => {
  const conference = useCurrentConference()

  return conference ? (
    <Typography variant="h6" noWrap>
      <MaybeRtlBox>{conference.name}</MaybeRtlBox>
    </Typography>
  ) : null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DarkModeButton = () => {
  const { setConfig } = useContainer(LayoutContext)
  const toggleDarkMode = useCallback(() => {
    setConfig?.((config) => ({ ...config, darkMode: !config.darkMode }))
  }, [setConfig])
  return (
    <IconButton onClick={toggleDarkMode}>
      <Brightness4 />
    </IconButton>
  )
}

const RefreshButton = () => {
  const [loading, setLoading] = useState(false)
  const apollo = useApolloClient()
  const refresh = useCallback(async () => {
    setLoading(true)
    await apollo.reFetchObservableQueries()
    setLoading(false)
  }, [apollo])
  return (
    <IconButton onClick={refresh}>
      {loading ? <CircularProgress color={'secondary'} size={24} /> : <Refresh />}
    </IconButton>
  )
}

// const PrintButton = ({ ...props }: IReactToPrintProps) => {
//   const print = useReactToPrint(props)
//   return (
//     <IconButton onClick={print}>
//       <Print />
//     </IconButton>
//   )
// }

const SearchParticipantsButton = () => {
  const popupState = usePopupState({ popupId: 'layoutsearch', variant: 'popover' })
  const { data, loading } = useQuery(MoveSelectedProductButtonDocument, {
    variables: { conferenceName: useCurrentConferenceName() },
    skip: !popupState.isOpen,
  })
  const { push } = useRouter()
  const classes = useLayoutComponentStyle({ runningEnv: undefined })
  const conferenceName = useCurrentConferenceName()
  return (
    <>
      <Tooltip title={'Search Participants'}>
        <IconButton {...bindTrigger(popupState)}>
          <Search />
        </IconButton>
      </Tooltip>
      <Popper {...bindPopper(popupState)}>
        <ClickAwayListener onClickAway={popupState.close}>
          <Box sx={{ padding: '16px' }} className={classes.search}>
            <Autocomplete
              style={{ width: 300 }}
              open={true}
              // getOptionSelected={(option, value) => option.idInConference === value.idInConference}
              getOptionLabel={(option) => option.label}
              options={data?.participants.map((v) => ({ value: v.idInConference, label: v.name })) || []}
              loading={loading}
              autoSelect={false}
              onChange={(e, selected) => {
                const part = data?.participants.find((v) => v.idInConference === selected?.value)
                if (part) {
                  popupState.close()
                  push(routes.admin.participant(conferenceName, part.idInConference).href)
                }
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="Search participants"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </ClickAwayListener>
        );
      </Popper>
    </>
  )
}

const useSidebarOpenState = createPersistedState('sidebarOpen')

export default function AdminLayout({ noLayout, children }: LayoutProps) {
  useDebugListener()
  const runningEnv = useMemo(() => {
    const url = getUrl()
    return url.includes('https://target-testing.herokuapp.com')
      ? 'staging'
      : url.includes('http://localhost:3000')
      ? 'dev'
      : undefined
  }, [])
  const classes = useLayoutComponentStyle({ runningEnv })
  const toolbarClasses = useToolbarStyles()
  const [persistedOpen, setPersistedOpen] = useSidebarOpenState(false)
  const [open, setOpen] = useState(true)
  useEffect(() => {
    setOpen(persistedOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setPersistedOpen(open)
  }, [open, setPersistedOpen])

  const { error, setError, loading, setLoadingStart, setLoadingEnd } = useAppContext()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const startHandler = () => setLoadingStart?.('LAYOUT')
    Router.events.on('routeChangeStart', startHandler)
    const completeHandler = () => setLoadingEnd?.('LAYOUT')
    Router.events.on('routeChangeComplete', completeHandler)
    return () => {
      Router.events.off('routeChangeStart', startHandler)
      Router.events.off('routeChangeComplete', completeHandler)
    }
  }, [setLoadingStart, setLoadingEnd])
  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus()
  const isLoading = loading || numPendingMutations > 0 || numPendingQueries > 0
  const snackbar = useCustomSnackbar()
  const adminUser = useQuery(CurrentAdminUserDocument).data?.myUser
  useEffect(() => {
    if (adminUser) {
      setUser({ name: adminUser.name, email: adminUser.email, id: adminUser.id })
    }
    return () => {
      configureScope((scope) => scope.setUser(null))
    }
  }, [adminUser])

  if (error) {
    if (
      error?.name === AuthenticationError.name ||
      (error as any).extensions?.code?.toLowerCase().includes('unauthenticated')
    ) {
      return (
        <Container maxWidth={'sm'}>
          <Box position={'relative'} width={'100%'} maxWidth={'600px'} mt={2} mb={2}>
            <a href={'https://www.target-conferences.com'} target={'_blank'} rel="noreferrer">
              <Image src={TargetLogo} alt={'Target Conferences Logo'} />
            </a>
          </Box>
          <PageTitle title={'Login'} pageTitle={'Target Conferences'}>
            <Box mt={2}>
              <Link {...routes.googleLogin(isSsrMode() ? '' : window.location.href)} passHref>
                <Button color={'primary'}>Sign in with Google</Button>
              </Link>
            </Box>
          </PageTitle>
        </Container>
      )
    }

    if (error?.name === ForbiddenError.name) {
      snackbar.showError('Access Denied')
    } else {
      snackbar.showError(error.message)
      setError?.()
    }
  }

  const handleDrawerOpen = () => setOpen(!open)
  const handleDrawerClose = () => setOpen(false)

  return (
    <LayoutContext.Provider initialState={{ adminUser }}>
      <CssBaseline />
      <RefreshVersionAlert />

      <div className={classes.root}>
        {noLayout ? null : (
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Box visibility={isLoading ? 'visible' : 'hidden'}>
              <LinearProgress color={'secondary'} />
            </Box>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              {runningEnv ? (
                <Box display={'flex'} alignItems={'center'} mr={2}>
                  <Typography>({runningEnv})</Typography>
                </Box>
              ) : null}
              <Box overflow={'hidden'}>
                <ToolbarTitle />
              </Box>
              <Box flex={1} display={'flex'} flexDirection={'row-reverse'}>
                {/*<Box ml={1}>*/}
                {/*  <PrintButton content={() => contentRef.current} />*/}
                {/*</Box>*/}
                <Box ml={1}>
                  <RefreshButton />
                </Box>
                <CurrentAdminUserAvatar />
                <Box ml={1}>
                  <SearchParticipantsButton />
                </Box>
                <ToolbarItemsSink />
              </Box>
            </Toolbar>
          </AppBar>
        )}
        <Box display={'flex'} width={'100%'} height={'100vh'} position={'fixed'} left={0} top={0}>
          {noLayout ? null : (
            <AppSidebar key={'appDrawer'} open={open} handleDrawerClose={handleDrawerClose} />
          )}
          <main className={classes.content} ref={contentRef}>
            {noLayout ? null : <div className={toolbarClasses.toolbar} />}
            {children}
          </main>
        </Box>
      </div>
    </LayoutContext.Provider>
  )
}

export const CurrentAdminUserAvatar = () => {
  const { adminUser } = useContainer(LayoutContext)
  const photoSrc = adminUser?.data?.profile?.photos?.[0]
  const logoutAdmin = useLogoutAdmin()
  const menuState = usePopupState({ variant: 'popover', popupId: 'userMenu' })
  const apolloClient = useApolloClient()

  if (!photoSrc || !adminUser) return null

  return (
    <Box ml={1} display={'flex'} alignItems={'center'}>
      <Tooltip title={'Logged in as ' + adminUser.name}>
        <Avatar
          imgProps={{ referrerPolicy: 'no-referrer' }}
          src={photoSrc.value}
          style={{ cursor: 'pointer' }}
          {...bindTrigger(menuState)}
        />
      </Tooltip>
      <Menu {...bindMenu(menuState)}>
        <Box pr={2} pl={2} pt={1} pb={1}>
          {adminUser?.name}
        </Box>
        <MenuItem
          onClick={async () => {
            await logoutAdmin()
            await apolloClient.reFetchObservableQueries()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}
