import * as Types from '../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReviewersListQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
}>;


export type ReviewersListQuery = (
  { __typename?: 'Query' }
  & { conference?: Types.Maybe<(
    { __typename?: 'Conference' }
    & { reviewers: Array<(
      { __typename?: 'Reviewer' }
      & ReviewerDataFragment
    )> }
  )> }
);

export type ReviewerDataFragment = (
  { __typename?: 'Reviewer' }
  & Pick<Types.Reviewer, 'id'>
  & { participant: (
    { __typename?: 'Participant' }
    & Pick<Types.Participant, 'id' | 'idInConference' | 'name' | 'roles'>
  ), reviews: Array<(
    { __typename?: 'Review' }
    & Pick<Types.Review, 'id' | 'data'>
    & { abstract: (
      { __typename?: 'Abstract' }
      & Pick<Types.Abstract, 'id' | 'idInConference' | 'title' | 'state'>
    ) }
  )> }
);

export const ReviewerDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ReviewerData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Reviewer"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"participant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"idInConference"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"roles"}}]}},{"kind":"Field","name":{"kind":"Name","value":"reviews"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"abstract"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"idInConference"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]}}]} as unknown as DocumentNode<ReviewerDataFragment, unknown>;
export const ReviewersListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ReviewersList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"shortNameEq"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reviewers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ReviewerData"}}]}}]}}]}},...ReviewerDataFragmentDoc.definitions]} as unknown as DocumentNode<ReviewersListQuery, ReviewersListQueryVariables>;