import { useQuery } from '@apollo/client/react/hooks'
import { VersionDocument } from './Layout.generated'
import { Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'

const CLIENT_VERSION = process.env.SOURCE_VERSION

export const RefreshVersionAlert = () => {
  const { data: versionData } = useQuery(VersionDocument, {
    pollInterval: 1000 * 60 * 5,
    fetchPolicy: 'network-only',
  })
  const serverVersion = versionData?.version

  return (
    <Snackbar
      open={(serverVersion && CLIENT_VERSION !== serverVersion) || false}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="error" variant={'filled'} elevation={10}>
        The application was updated. Please refresh the page to avoid errors.
        <Button color={'secondary'} onClick={() => window.location.reload()} variant={'text'}>
          Refresh
        </Button>
      </Alert>
    </Snackbar>
  )
}
