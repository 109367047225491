import * as Types from '../../../generated/graphql';

import { RegistrationShowDataFragment } from '../../../pages/admin/[conferenceName]/participants/[id].generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { RegistrationShowDataFragmentDoc } from '../../../pages/admin/[conferenceName]/participants/[id].generated';
export type MoveSelectedProductButtonQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
}>;


export type MoveSelectedProductButtonQuery = (
  { __typename?: 'Query' }
  & { participants: Array<(
    { __typename?: 'Participant' }
    & Pick<Types.Participant, 'id' | 'name' | 'idInConference'>
  )> }
);

export type MoveSelectedProductMutationVariables = Types.Exact<{
  selectedProductId: Types.Scalars['ID'];
  participantId: Types.Scalars['ID'];
}>;


export type MoveSelectedProductMutation = (
  { __typename?: 'Mutation' }
  & { moveSelectedProduct: (
    { __typename?: 'Participant' }
    & RegistrationShowDataFragment
  ) }
);


export const MoveSelectedProductButtonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MoveSelectedProductButton"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"participants"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}},{"kind":"Argument","name":{"kind":"Name","value":"orderBy"},"value":{"kind":"EnumValue","value":"name"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"idInConference"}}]}}]}}]} as unknown as DocumentNode<MoveSelectedProductButtonQuery, MoveSelectedProductButtonQueryVariables>;
export const MoveSelectedProductDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveSelectedProduct"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"selectedProductId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"participantId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveSelectedProduct"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"selectedProductId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"selectedProductId"}}},{"kind":"Argument","name":{"kind":"Name","value":"participantId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"participantId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RegistrationShowData"}}]}}]}},...RegistrationShowDataFragmentDoc.definitions]} as unknown as DocumentNode<MoveSelectedProductMutation, MoveSelectedProductMutationVariables>;