import useSnackbar from '../../lib/useSnackbar'
import { useEffect } from 'react'
import isHotkey from 'is-hotkey'
import useAppStore from './useAppStore'

export const useDebugListener = () => {
  const snackbar = useSnackbar()
  useEffect(() => {
    const keyDownCallback: EventListener = (e) => {
      if (isHotkey('alt+ctrl+shift+d', e as any)) {
        snackbar.showSuccess('DEBUG mode activated')
        useAppStore.setState({ isDebug: true })
      }
    }
    window.addEventListener('keydown', keyDownCallback)
    return () => {
      window.removeEventListener('keydown', keyDownCallback)
    }
  }, [snackbar])
}
