import { useMemo } from 'react'
import { useSnackbar as useOriginalSnackbar } from 'material-ui-snackbar-provider'
import { SnackbarProps } from '@material-ui/core'

export default function useCustomSnackbar() {
  const snackbar = useOriginalSnackbar()
  return useMemo(() => {
    const showMessage =
      (type: string) =>
      (
        message: string,
        {
          action,
          handleAction,
          customParameters,
        }: {
          action?: string
          handleAction?: () => void
          customParameters?: SnackbarProps
        } = {}
      ) =>
        snackbar.showMessage(message, action, handleAction, {
          ...customParameters,
          type,
        })
    return {
      ...snackbar,
      showMessage: showMessage('info'),
      showInfo: showMessage('info'),
      showWarning: showMessage('warning'),
      showError: showMessage('error'),
      showSuccess: showMessage('success'),
    }
  }, [snackbar])
}
