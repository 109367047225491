import { useApolloClient } from '@apollo/client/react/hooks'

export const useLogoutAdmin = () => {
  const client = useApolloClient()
  return async () => {
    await client.resetStore()
    await fetch('/api/logout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
  }
}
