import create from 'zustand'
import { persist } from 'zustand/middleware'

export const useAppStore = create<{
  isDebug: boolean
  dndIsOverState: boolean
  useNewParticipantsTable: boolean
  useNewReports: boolean
}>()(
  persist(
    (set, get) => ({
      isDebug: false,
      dndIsOverState: false,
      useNewParticipantsTable: false,
      useNewReports: false,
    }),
    {
      name: 'appStore', // unique name
      getStorage: () => localStorage,
      partialize: ({ useNewParticipantsTable, useNewReports }) => ({
        useNewParticipantsTable,
        useNewReports,
      }),
    }
  )
)

export const useIsDebug = () => useAppStore((v) => v.isDebug)
export const useDndIsOverState = () => useAppStore((v) => v.dndIsOverState)
export default useAppStore
