import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/icons/Menu'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AppRoute, routes } from '../../routes'
import { useAppRoutes } from '../../useAppRoutes'

const AppRouteEl = ({
  title,
  route,
  icon,
  children,
  tooltip,
  depth,
}: AppRoute & { tooltip: boolean; depth: number }) => {
  return (
    <ListItemLink {...route} primary={title} icon={icon} tooltip={tooltip} depth={depth}>
      {children?.length
        ? children.map((appRoute) => (
            <AppRouteEl key={appRoute.route.href} {...appRoute} tooltip={tooltip} depth={depth + 1} />
          ))
        : null}
    </ListItemLink>
  )
}

export function AppSidebar({ open, handleDrawerClose }: { open: boolean; handleDrawerClose: () => void }) {
  const classes = useStyles()
  const toolbarClasses = useToolbarStyles()
  const appRoutes = useAppRoutes()

  return (
    <Drawer
      variant={'permanent'}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={toolbarClasses.toolbar}>
        <Box overflow={'hidden'}></Box>
        <IconButton onClick={handleDrawerClose}>
          <Menu />
        </IconButton>
      </div>
      <List>
        <ListItemLink
          {...routes.admin.home}
          primary={'Main Menu'}
          icon={<ArrowBack />}
          tooltip={!open}
          depth={0}
        />
        <Divider />
        {appRoutes.map((appRoute) => (
          <AppRouteEl key={appRoute.route.href} {...appRoute} tooltip={!open} depth={0} />
        ))}
      </List>
    </Drawer>
  )
}
export const useToolbarStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    '@media print': {
      display: 'none',
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  link: {
    whiteSpace: 'nowrap',
  },
  text: {
    overflow: 'hidden',
    '& *': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(7) + 1
    // }
  },
}))

// const useOpenStateObject = createPersistedState('listItemLink')
const useOpenState = (name: string) => {
  // const [stateObject, setState] = useOpenStateObject({})
  const [stateObject, setState] = useState<Record<string, any>>({})
  const setNewState = useCallback(
    (state: boolean) =>
      setState(stateObject[name] !== state ? { ...stateObject, [name]: state } : stateObject),
    [name, stateObject]
  )
  return [stateObject[name], setNewState]
}

const ListItemLink = ({
  icon,
  primary,
  href,
  tooltip,
  children,
  depth,
}: {
  primary: string
  href: string
  tooltip: boolean
  icon?: ReactNode
  children?: ReactNode
  depth: number
}) => {
  const classes = useStyles()

  const { asPath } = useRouter()
  const match = useMemo(() => {
    const pathNameWithout = asPath.split('?')[0]
    return pathNameWithout.includes(href.split('?')[0])
  }, [asPath, href])
  const [stateExpanded, setExpanded] = useOpenState(href)
  const [expanded, setStateExpanded] = useState(match)

  useEffect(() => {
    if (match) {
      setExpanded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match])

  useEffect(() => {
    setStateExpanded(stateExpanded)
  }, [stateExpanded, expanded])

  const ref = useRef<HTMLDivElement>(null)
  const [tooltipAnyway, setTooltipAnyway] = useState(false)
  useLayoutEffect(() => {
    setTooltipAnyway((ref.current?.scrollWidth || 0) > (ref.current?.offsetWidth || 0))
  }, [])

  return (
    <>
      <Link href={href}>
        <a className={classes.a}>
          <Tooltip disableHoverListener={!tooltip && !tooltipAnyway} title={primary}>
            <Box pl={depth}>
              <ListItem button selected={asPath.split('?')[0] === href.split('?')[0]}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText
                  primary={primary}
                  className={classes.text}
                  primaryTypographyProps={{ ref: ref }}
                />
                {children && (
                  <IconButton
                    size="small"
                    onClick={(event: any) => {
                      event.preventDefault()
                      setExpanded(!expanded)
                    }}
                  >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </ListItem>
            </Box>
          </Tooltip>
        </a>
      </Link>
      {children && (
        <Collapse in={expanded}>
          <List disablePadding>{children}</List>
        </Collapse>
      )}
    </>
  )
}
export const DRAWER_WIDTH = 240
